<template>
  <div class="subtract text-center text-primary p-6">
    <p class="font-bold solution-exval">Solution Exval</p>
    <p class="Facturation-mensuelle mb-16">Facturation mensuelle</p>

    <div class="grid grid-cols-2 -x-1-membre-x-1-mois mb-16">
      <p class="text-left">
        79,00 €<br />
        x 1 membre x 1 mois
      </p>
      <p class="text-right">79,00 €</p>
    </div>

    <div class="grid grid-cols-2 -x-1-membre-x-1-mois mb-12">
      <p class="text-left">TVA</p>
      <p class="text-right">0,00 €</p>
    </div>

    <div
      class="grid grid-cols-2 mb-10 -x-1-membre-x-1-mois text-tertiary font-bold"
    >
      <p class="text-left">Montant total à régler</p>
      <p class="text-right">79,00 €</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Substract",
};
</script>

<style scoped>
.subtract {
  height: 350px;
  background-image: url("../../assets/b2b10/image24.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.solution-exval {
  font-size: 13px;
  line-height: 1.85;
}
.Facturation-mensuelle {
  font-size: 14px;
  line-height: 1.71;
}
.-x-1-membre-x-1-mois {
  font-size: 13px;
  line-height: 1.23;
}
</style>