<template>
   <div class="flex flex-col testimonial bg-white p-8">
        <div class="member-name text-primary">
            Aziz Ghodbani<br/>AZKS
        </div>
       <div class="text-primary about-member">
           16B Boulevard Marechal Joffre<br>
           38000 Grenoble<br>
           France<br>
           Tél : 0660197807<br>
           Email : aziz.ghodbani@gmail.com<br><br>
           N°TVA : FR289828292
       </div>
   </div>
</template>

<script>
    export default {
        name: "AccountCard"
    }
</script>

<style scoped>
.testimonial {
    width: 392px;
    gap: 10px;
    border-radius: 16px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
}
.member-name {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
}
.about-member {
    font-size: 16px;
    line-height: 1.88;
}
</style>