<template>
  <div class="flex flex-row flex-wrap justify-around">
    <div
      class="flex flex-col flex-wrap justify-center md:ml-40 md:mr-40 md:pb-36"
    >
      <span class="text-primary text-2xl font-bold pb-20 pt-14">
        Valider votre souscription
      </span>
      <account-card />
      <div class="flex flex-wrap pt-10 md:pt-20 mb-10">
        <button
          class="CTA-big-outline-2 px-6 text-primary text-sm custom-button md:mr-5"
        >
          Modifier ma commande
        </button>
        <button
          class="CTA-big-outline-2 px-6 mt-5 md:mt-0 text-white text-sm bg-primary custom-button"
          @click="toTerminaison()"
        >
          Je m'abonne à mon forfait ExVal
        </button>
      </div>
    </div>
    <div
      class="flex-grow flex flex-row justify-center items-center bg-secondary p-4"
    >
      <substract />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "b2b10View",
  setup() {
    const router = useRouter();
    const toTerminaison = () =>
      router.push("/tarifs/souscription/validation/terminaison");
    return {
      toTerminaison,
    };
  },
  components: {
    AccountCard: require("../components/b2b10/AccountCard").default,
    Substract: require("../components/b2b10/Substract").default,
  },
});
</script>
<style scoped>
.custom-button {
  border: solid 1px #363660;
}
</style>